import React, { useEffect, useRef, useState } from 'react'
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// Custom hook for initializing Swiper
const useInitializeSwiper = (swiperRef) => {
	useEffect(() => {
		if (swiperRef.current) {
			const swiperInstance = new Swiper(swiperRef.current, {
				modules: [Navigation, Pagination],
				navigation: {
					nextEl: '.button-next',
					prevEl: '.button-prev'
				},
				slidesPerView: 1,
				spaceBetween: 24,
				lazy: true,
				// preventInteractionOnTransition: true,
				breakpoints: {
					768: {
						slidesPerView: 1.1
					},
					1024: {
						slidesPerView: 1.2
					}
				},
				speed: 800
			})

			// Update Swiper on resize
			const resizeHandler = () => swiperInstance.update()
			window.addEventListener('resize', resizeHandler)

			return () => {
				window.removeEventListener('resize', resizeHandler)
				swiperInstance.destroy()
			}
		}
	}, [swiperRef])
}

const Carousel = ({ images }) => {
	const [isInitialized, setIsInitialized] = useState(false)
	const swiperRef = useRef(null)

	useInitializeSwiper(swiperRef)

	useEffect(() => {
		setIsInitialized(true)
	}, [])

	return (
		<div
			className={`swiper-container ${isInitialized ? 'fade-in' : 'invisible'} relative flex flex-col overflow-hidden px-6 md:px-0`}
			ref={swiperRef}
		>
			<div className="swiper-wrapper">
				{images.map((item, index) => (
					<div className="swiper-slide" key={index}>
						<div className="aspect-h-12 aspect-w-16">
							<img
								src={item.imageUrl}
								alt={item.alt || 'Portfolio Image'}
								className="h-full w-full rounded-sm object-cover pb-6 brightness-70"
							/>
							<a
								href={item.category ? `/${item.category}/${item.slug}` : `/portfolio/${item.slug}`}
								className="group"
							>
								<div className="absolute bottom-6 left-0 right-0 z-10 w-full font-serif text-2xl text-stone-50">
									<div className="m-6 grid grid-cols-4 justify-between gap-6">
										<div className="col-span-3 group-hover:font-italic">{item.caption}</div>
										<div className="col-span-1 mb-[2px] flex h-6 w-6 items-center justify-center place-self-end rounded-full border border-stone-50 fill-stone-50 text-stone-50 transition-all duration-500 group-hover:bg-stone-50 group-hover:fill-aztec-950 group-hover:text-aztec-950">
											<div className="h-1/2 w-1/2">
												<svg viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
													<path d="M515 1955l930-931L515 93l90-90 1022 1021L605 2045z" />
												</svg>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
					</div>
				))}
			</div>
			<div className="flex justify-center gap-6 py-2 md:justify-end md:px-6 md:py-6">
				<button
					className="button-prev group flex h-10 w-10 rotate-180 items-center justify-center rounded-full border border-aztec-950"
					aria-label="Previous image"
				>
					<svg
						viewBox="0 0 2048 2048"
						xmlns="http://www.w3.org/2000/svg"
						className="fill-aqua-800 stroke-aqua-800 group-hover:animate-moving-arrow-right w-4 stroke-2"
					>
						<path d="M515 1955l930-931L515 93l90-90 1022 1021L605 2045z" />
					</svg>
				</button>
				<button
					className="button-next group flex h-10 w-10 items-center justify-center rounded-full border border-aztec-950"
					aria-label="Next image"
				>
					<svg
						viewBox="0 0 2048 2048"
						xmlns="http://www.w3.org/2000/svg"
						className="fill-aqua-800 stroke-aqua-800 group-hover:animate-moving-arrow-left w-4 stroke-2"
					>
						<path d="M515 1955l930-931L515 93l90-90 1022 1021L605 2045z" />
					</svg>
				</button>
			</div>
		</div>
	)
}

export default Carousel
